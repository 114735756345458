import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJSON from "./translations/en.json";
import bgJSON from "./translations/bg.json";
import deJSON from "./translations/de.json";
import elJSON from "./translations/el.json";
import frJSON from "./translations/fr.json";
import huJSON from "./translations/hu.json";
import itJSON from "./translations/it.json";
import roJSON from "./translations/ro.json";
import ruJSON from "./translations/ru.json";
import trJSON from "./translations/tr.json";
import hiJSON from "./translations/hi.json";
import esJSON from "./translations/es.json";
import ptJSON from "./translations/pt.json";
import csJSON from "./translations/cs.json";
import hrJSON from "./translations/hr.json";
import srJSON from "./translations/sr.json";

const resources = {
  en: {
    translation: enJSON,
  },
  bg: {
    translation: bgJSON,
  },
  de: {
    translation: deJSON,
  },
  el: {
    translation: elJSON,
  },
  fr: {
    translation: frJSON,
  },
  hu: {
    translation: huJSON,
  },
  it: {
    translation: itJSON,
  },
  ro: {
    translation: roJSON,
  },
  ru: {
    translation: ruJSON,
  },
  tr: {
    translation: trJSON,
  },
  hi: {
    translation: hiJSON,
  },
  es: {
    translation: esJSON,
  },
  pt: {
    translation: ptJSON,
  },
  cs: {
    translation: csJSON,
  },
  hr: {
    translation: hrJSON,
  },
  sr: {
    translation: srJSON,
  },
};
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    nonExplicitSupportedLngs: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: resources,
  });

export default i18n;
