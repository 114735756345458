import { useState } from "react";
import { useCookies } from "react-cookie";
import Modal from "react-modal";
import {
  DISABLE_LOCATION_CONSENT,
  LOCATION_CONSENT_COOKIE,
} from "../Constants";
import WorldLocation from "../icons/WorldLocation.svg";
import { useTranslation } from "react-i18next";

const ButtonStyle: React.CSSProperties = {
  border: 0,
  color: "#0069B3",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0em",
  height: "34px",
  padding: "11px, 24px, 12px, 24px",
  borderRadius: "40px",
};

const LocationButton = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  const [isHover, setIsHover] = useState(false);
  const onMouse = () => setIsHover(!isHover);

  const finalStyle = {
    ...ButtonStyle,
    ...props.style,
    backgroundColor: isHover ? "#E6F0F7" : "#FFFFFF",
  };

  return (
    <button
      {...props}
      onMouseEnter={onMouse}
      onMouseLeave={onMouse}
      style={finalStyle}
    />
  );
};

export const LocationConsent = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([LOCATION_CONSENT_COOKIE]);

  if (DISABLE_LOCATION_CONSENT) {
    return null;
  }

  return (
    <Modal
      isOpen={cookies[LOCATION_CONSENT_COOKIE] === undefined}
      ariaHideApp={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "296px",
          height: "296px",
          borderRadius: "24px",
          border: "2px solid #B0D1E7",
          boxShadow:
            "0px 3px 6px 0px #004B7F1A, 0px 12px 12px 0px #004B7F17, 0px 26px 16px 0px #004B7F0D",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        },
      }}
    >
      <div
        style={{
          width: "249px",
          height: "41px",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "28px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#0069B3",
        }}
      >
        {t("locationConsentTitle")}
      </div>
      <div
        style={{
          width: "48px",
          height: "48px",
          backgroundColor: "#E6F0F7",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <img src={WorldLocation} alt="World Location" />
      </div>
      <div
        style={{
          width: "249px",
          height: "40px",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0em",
        }}
      >
        {t("locationConsentText")}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <LocationButton
          onClick={() => setCookie(LOCATION_CONSENT_COOKIE, false)}
          style={{ width: "136px" }}
        >
          {t("locationConsentDecline")}
        </LocationButton>
        <LocationButton
          onClick={() => setCookie(LOCATION_CONSENT_COOKIE, true)}
          style={{ width: "104px" }}
        >
          {t("locationConsentAccept")}
        </LocationButton>
      </div>
    </Modal>
  );
};
