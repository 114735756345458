import { useTranslation } from "react-i18next";
import { Marker } from "../interfaces/MarkersResult";
import { isMobile } from "../services/Utilities";
import { useContext, useEffect, useRef, useState } from "react";
import { StorePin } from "./StorePin";
import ThemeContext from "../services/ThemeContext";
import { isDesktop } from "../Constants";

interface ClosestPointModalProps {
  point: Marker | undefined;
  distance?: number;
  map?: google.maps.Map;
  modalClicked?: () => void;
  platform?: "storelocator" | "iframe";
  pinSVG?: string;
  text?: string;
}

export const ClosestPointModal = ({
  point,
  distance,
  map,
  modalClicked,
  platform = "storelocator",
  pinSVG,
  text
}: ClosestPointModalProps) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const _isMobile = isMobile();
  const animateRef = useRef<HTMLDivElement>(null);
  const injectStyle = (style: string) => {
    const styleElement = document.createElement("style");
    let styleSheet = null;
    document.head.appendChild(styleElement);
    styleSheet = styleElement.sheet;
    styleSheet?.insertRule(style, styleSheet.cssRules.length);
  };

  const marqueeWidth = document.getElementById("marquee")?.clientWidth ?? 0;
  const [translatedPx, setTranslatedPx] = useState(0);
  useEffect(() => {
    if (animateRef.current)
      setTranslatedPx(animateRef.current.scrollWidth - marqueeWidth);
  }, [marqueeWidth]);
  const marqueeStyle = `
      @keyframes marquee {
        0%   { transform: translate(0%) }
        50% { transform: translate(${-translatedPx}px) }
        100%{ transform: translate(0%) }
      }
    `;
  injectStyle(marqueeStyle);
  const clickHandler = () => {
    map?.setCenter({
      lat: Number(point?.lat),
      lng: Number(point?.lng)
    });
    modalClicked && modalClicked();
  };
  if (!point) return null;
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {platform === "iframe" && (
        <div
          className={`bg-${theme["200"]} text-slate-800`}
          style={{
            maxWidth: "160px",
            fontSize: "11px",
            padding: "4px 15px",
            borderRadius: "8px",
            position: "relative",
            top: "14px"
          }}
        >
          {t("tapForDirections")}
        </div>
      )}
      <button
        className={`border-2 border-solid border-${theme["200"]} bg-slate-50 text-slate-800`}
        style={{
          borderRadius: "22.054px",
          border: platform === "storelocator" ? "2px solid #BAE0D5" : undefined,
          background: platform === "storelocator" ? "#FFF" : undefined,
          boxShadow:
            "0px 340px 95.568px 0px rgba(14, 65, 51, 0.00), 0px 216.865px 86.378px 0px rgba(14, 65, 51, 0.01), 0px 123.135px 73.514px 0px rgba(14, 65, 51, 0.05), 0px 55.135px 55.135px 0px rgba(14, 65, 51, 0.09), 0px 12.865px 29.405px 0px rgba(14, 65, 51, 0.10)",
          position: platform === "storelocator" ? "fixed" : "static",
          height: _isMobile
            ? "100px"
            : platform === "storelocator"
              ? "140px"
              : "auto",
          width:
            _isMobile && platform === "storelocator"
              ? "calc(100% - 32px)"
              : platform === "storelocator"
                ? "40vw"
                : "100%",
          maxWidth: "560px",
          left: platform === "storelocator" ? "50%" : "auto",
          bottom: platform === "storelocator" ? "30px" : "auto",
          transform: platform === "storelocator" ? "translate(-50%)" : "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: _isMobile ? "flex-start" : "space-evenly",
          gap: _isMobile ? "2px" : "",
          paddingLeft: _isMobile ? "10px" : "",
          color: platform === "storelocator" ? "#000" : undefined,
          paddingTop: "8px",
          margin: platform === "iframe" ? "0 16px" : undefined,
          fontFamily: "inherit"
        }}
        onClick={clickHandler}
      >
        <StorePin
          style={{
            height: _isMobile ? "32px" : "48px",
            margin: "12px"
          }}
          pinSVG={pinSVG}
        />
        <div
          style={{
            textAlign: "left",
            // maxWidth: _isMobile ? "45vw" : "30vw"
            maxWidth:
              platform === "storelocator" && _isMobile
                ? "45vw"
                : platform === "iframe" && isDesktop
                  ? "100%"
                  : platform === "iframe" && !isDesktop
                    ? "70%"
                    : "25vw"
          }}
        >
          {text ?? (
            <>
              <div
                className={"text-slate-800"}
                style={{
                  fontSize: _isMobile
                    ? "12px"
                    : platform === "storelocator"
                      ? "20px"
                      : "14px",
                  fontWeight: 400,
                  lineHeight: _isMobile ? "16px" : "24px",
                  color: platform === "storelocator" ? "#000" : undefined
                }}
              >
                {`${t("closestModalTitle")}:`}
              </div>

              <div
                className={"marquee text-slate-600"}
                id={"marquee"}
                style={{
                  fontSize: _isMobile
                    ? "16px"
                    : platform === "storelocator"
                      ? "23px"
                      : "16px",
                  // fontWeight: platform === "iframe" || _isMobile ? 500 : 700,
                  lineHeight:
                    platform === "storelocator"
                      ? _isMobile
                        ? "24px"
                        : "33.6px"
                      : "21px",
                  color: platform === "storelocator" ? "#000" : undefined,
                  whiteSpace: "nowrap",
                  overflowX: "scroll",
                  display: "flex",
                  scrollbarWidth: "none"
                }}
              >
                <span
                  ref={animateRef}
                  onMouseEnter={() =>
                    animateRef.current?.getAnimations()[0]?.pause()
                  }
                  onMouseLeave={() =>
                    animateRef.current?.getAnimations()[0]?.play()
                  }
                  style={{
                    // animation:
                    //   animateRef.current !== null &&
                    //   animateRef.current.clientWidth > marqueeWidth
                    //     ? "marquee 10s linear infinite"
                    //     : "none",
                    display: "inline-block",
                    whiteSpace: "nowrap"
                  }}
                >
                  {point.name}
                </span>
              </div>
              {distance ? (
                <div
                  className={"text-slate-600"}
                  style={{
                    fontSize: _isMobile
                      ? "14px"
                      : platform === "storelocator"
                        ? "23px"
                        : "16px",
                    fontWeight: 400,
                    lineHeight: _isMobile ? "20px" : "33.6px",
                    color: platform === "storelocator" ? "#000" : undefined
                  }}
                >
                  {distance >= 1000
                    ? Math.round(distance / 100) / 10
                    : Math.round(distance)}
                  {distance >= 1000 ? "km" : "m"}
                </div>
              ) : null}
            </>
          )}
        </div>
      </button>
    </div>
  );
};
