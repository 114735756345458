import { useTranslation } from "react-i18next";

const Schedule = ({
  showSchedule,
  schedule,
}: {
  showSchedule: boolean;
  schedule: { day: string; working_hours: string }[] | undefined;
}) => {
  const { t } = useTranslation();

  let workingHourStyle: { justifyContent: string };
  schedule?.forEach((item) => {
    if (item.working_hours.includes(",")) {
      workingHourStyle = { justifyContent: "flex-start" };
    }
  });
  return (
    <div className={`schedule ${!showSchedule && "hideItem"}`}>
      <ul>
        {schedule?.map((item: { day: string; working_hours: string }) => {
          if (item.working_hours.trim() === "OpMn 24 hour") {
            item.working_hours = t("open24Hours");
          }

          return (
            <li key={item.day}>
              <p>{item.day.replace(/\s/g, "")}</p>
              <p className={"working-hours"} style={workingHourStyle}>
                {item.working_hours.includes(":")
                  ? item.working_hours.replace(/\s/g, "")
                  : item.working_hours}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Schedule;
