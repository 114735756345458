import { ApiKeys } from "../interfaces/ApiKeys";
import { ApiClient } from "./Utilities";

export class APIKeysService {
  public static fetchKeys() {
    if (
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY &&
      process.env.REACT_APP_GOOGLE_MAPS_MAP_ID
    )
      return {
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        googleMapsId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
      };
    return ApiClient.get<ApiKeys>("/fetch/params").then(
      (result) => result.data
    );
  }
}
