import time_icon from "../icons/time_icon.svg";
import arrow__down from "../icons/arrow__down.svg";
import arrow__up from "../icons/arrow__up.svg";
import { LocationDetails } from "../interfaces/LocationDetails";
import { useTranslation } from "react-i18next";
import { MouseEventHandler } from "react";
// import {
//   add1200,
//   addTimeSymbol,
//   getSchedule,
//   removeTimeSymbol,
// } from "../services/Utilities";

const OpenNow = ({
  showSchedule,
  toggleSchedule,
  locationSchedule,
  data,
}: {
  showSchedule: boolean;
  toggleSchedule: MouseEventHandler<HTMLImageElement>;
  locationSchedule: string[] | undefined;
  data: LocationDetails | undefined;
}) => {
  const { t } = useTranslation();
  const mobileView = window.innerWidth <= 500;

  const isOpen = () => {
    if (locationSchedule) {
      if (data?.opening_hours?.isOpen()) {
        if (
          //if its open 24/7
          data?.opening_hours?.periods &&
          data?.opening_hours?.periods.length <= 1
        ) {
          return <span className={"isOpen"}>{t("open24Hours")}</span>;
        } else {
          return (
            <>
              <span className={"isOpen"}>{t("openNow")}</span>
            </>
          );
        }
      } else
        return (
          <>
            <span className={"isClosed"}>{t("closed")}</span>
          </>
        );
    } else
      return <span className={"isClosed"}>{t("noScheduleAvailable")}</span>;
  };
  return (
    <div className={`open-now`}>
      <div>
        <img src={time_icon} alt={"open now"} />
        <p>{isOpen()}</p>
      </div>
      {isOpen().props.children !== t("noScheduleAvailable") && (
        <img
          className={`${!showSchedule && "rotateVertically"}${!mobileView && " hideItem"}`}
          src={`${!showSchedule ? arrow__down : arrow__up}`}
          alt={"arrow down"}
          onClick={toggleSchedule}
        />
      )}
    </div>
  );
};
export default OpenNow;
