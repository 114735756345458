import { useTranslation } from "react-i18next";
import direction_icon from "../icons/direction_icon.svg";

type GetDirectionsProps = {
  directionsTo: { lat: number | undefined; lng: number | undefined };
};

const GetDirections = ({ directionsTo }: GetDirectionsProps) => {
  const { t } = useTranslation();
  return (
    <a
      className={"get-directions"}
      href={`https://www.google.com/maps/dir/?api=1&destination=${directionsTo.lat},${directionsTo.lng}`}
      target={"_blank"}
      rel="noreferrer"
    >
      <img src={direction_icon} alt={"get directions"} />
      <p>{t("getDirections")}</p>
    </a>
  );
};

export default GetDirections;
