import location_icon from "../icons/location_icon.svg";
import { LocationDetails } from "../interfaces/LocationDetails";
import { useTranslation } from "react-i18next";
import { copyText } from "../services/Utilities";

const LocationAddress = ({ data }: { data: LocationDetails | undefined }) => {
  const { t } = useTranslation();

  const IfValueExists = (val: string | null | undefined, comma: boolean) => {
    if (val && val !== "NULL" && val !== "null") {
      if (comma) {
        return `, ${val}`;
      } else return val;
    } else {
      return "";
    }
  };
  const addressText = `${IfValueExists(
    data?.address?.street,
    false
  )} ${IfValueExists(data?.address?.number, false)}
     ${IfValueExists(data?.address?.city, true)}  ${IfValueExists(
       data?.address?.postalCode,
       true
     )} ${IfValueExists(data?.address?.country, true)}`
    .trim()
    .replace("_", " ") // replaces underscore with space
    .replace(/\s*,/g, ",") // removes whitespaces before commas
    .replace(/^,+/, ""); // removes first character is its comma
  return (
    <div className={"click-to-copy"}>
      <div className={"click-to-copy-icon-text"}>
        <img src={location_icon} alt={"location icon"} />
        <p>{t("clickToCopy")}</p>
      </div>
      <p
        onClick={() => copyText(addressText.toUpperCase())}
        style={{ textTransform: "uppercase" }}
      >
        {addressText}
      </p>
    </div>
  );
};
export default LocationAddress;
