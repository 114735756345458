import {CSSProperties} from "react";
import {PRODUCT_DETAILS} from "../Constants";

export const StorePin = ({style, pinSVG = PRODUCT_DETAILS.pinSVG}: {
  style: CSSProperties,
  pinSVG?: string
}) => {
  return <img src={pinSVG} style={style}
              alt="Store Pin"/>;
};
