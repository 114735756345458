import { useTranslation } from "react-i18next";

const CenterMapText = ({ show }: { show: boolean }) => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  if (!show) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        right: "0px",
        zIndex: 4,
        bottom: "190px",
        maxWidth: "140px",
        background: "#000",
        color: "#fff",
        padding: "10px",
        borderRadius: "10px",
        fontSize: "0.9em",
      }}
    >
      <p style={{ margin: 0 }}>{t("cannotAccessLocation")}</p>
      <a
        href={`https://support.google.com/maps/answer/2839911?hl=${currentLanguage}`}
        target={"_blank"}
        style={{ textDecoration: "none", color: "#8ab4f8" }}
      >
        {t("learnMore")}
      </a>
    </div>
  );
};

export default CenterMapText;
