import {FC, useContext} from "react";
import CookieConsent from "react-cookie-consent";
import {isMobile} from "../services/Utilities";
import {useTranslation} from "react-i18next";
import "./LocationDetails.css";
import {COOKIES_CONSENT_COOKIE} from "../Constants";
import ThemeContext from "../services/ThemeContext";

interface CookieConsentProps {
  roundedTopCorners?: boolean;
  buttonOriantation?: "vertical" | "horizontal";
  roundedButtons?: boolean;
  displayTitle?: boolean,
  platform?: "storelocator" | "iframe";

}

const _CookieConsent: FC<CookieConsentProps> = ({platform = "storelocator"}) => {
  const _isMobile = isMobile();
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);

  const MainButtonStyle: React.CSSProperties = {
    // width: _isMobile ? "100px" : "160px",
    height: _isMobile ? "55px" : "48px",
    padding: "0px 53px 0px 53px",
    borderRadius: "12px",
    fontFamily: "Cera Pro Regular",
    fontSize: "16px",
    fontWeight: 700,
    letterSpacing: "0em",
    margin: _isMobile ? "8px" : "15px",
  };
  return (
    <CookieConsent
      enableDeclineButton
      buttonText={t("accept")}
      declineButtonText={t("decline")}
      cookieName={COOKIES_CONSENT_COOKIE}
      contentStyle={{
        margin: _isMobile ? "10px 8px 10px 8px" : "15px",
        textAlign: "left",
      }}
      buttonClasses={`bg-${theme["600"]} text-${theme["50"]}`}
      buttonStyle={{
        ...MainButtonStyle,
        color: platform === "storelocator" ? "#F0F8FF" : undefined,
        background: platform === "storelocator" ? "#0079CB" : undefined,
        border: platform === "storelocator" ? "2px solid #3387C2" : undefined,
      }}
      declineButtonClasses={`bg-${theme["100"]} text-${theme["600"]}`}
      declineButtonStyle={{
        ...MainButtonStyle,
        color: platform === "storelocator" ? "#0079CB" : undefined,
        background: platform === "storelocator" ? "#FFFFFF" : undefined,
        border: platform === "storelocator" ? "2px solid #7BCCFE" : undefined,
      }}
      containerClasses={`border-2 border-b-0 border-solid border-${theme["200"]}`}
      style={{
        background: "#FFFFFF",
        color: "#6D6D6D",
        fontFamily: "Cera Pro Regular",
        fontSize: "16px",
        fontWeight: _isMobile ? 400 : 500,
        lineHeight: "24px",
        letterSpacing: "0em",
        textAlign: "left",
        verticalAlign: "center",
        alignItems: "center",
        justifyContent: _isMobile ? "right" : "center",
        borderTop: platform === "storelocator" ? "1px solid #B0D1E7" : undefined,
        minHeight: _isMobile ? "326px" : "105px",
        width: "100%",
        borderRadius: _isMobile ? "14px 14px 0px 0px" : "0px"
      }}
      buttonWrapperClasses={_isMobile ? "consent-buttons-wrapper" : ""}
    >
      <div className={"text-slate-600"} style={{
        marginBottom: "8px",
        color: platform === "storelocator" ? "#3D3D3D" : undefined
      }}>{t("cookieConsentTitle")}</div>
      {t("cookiesConsentText")}{" "}
      <a href="https://oktocash.eu/cookies-policy/" className={`text-${theme["600"]}`}
         style={{textDecoration: "underline"}}>{t("cookiePolicy")}</a>.
    </CookieConsent>
  );
};

export default _CookieConsent;
