import { Marker } from "../interfaces/MarkersResult";
import { calculateDistanceBetween } from "./Utilities";

export const ExcludedGooglePlacesTypes = ["church"];
export class PosDetailsService {
  private service: google.maps.places.PlacesService;
  constructor(map: google.maps.Map) {
    this.service = new google.maps.places.PlacesService(map);
  }

  private async getPlaceDetailsById(placeID: string | undefined) {
    return new Promise<google.maps.places.PlaceResult | null>(
      (resolve, reject) => {
        if (!placeID) return resolve(null);
        this.service.getDetails(
          {
            placeId: placeID,
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(place);
            } else {
              reject(new Error(status));
            }
          }
        );
      }
    );
  }
  public async fetchPosDetails(
    selectedMarker: Marker | undefined
  ): Promise<google.maps.places.PlaceResult | null> {
    try {
      if (!selectedMarker?.address?.street) return null;
      const request = {
        query: `${selectedMarker?.name} ${selectedMarker.address.street} ${selectedMarker.address.city}`,
        fields: ["place_id"],
      };
      return await new Promise((resolve, reject) => {
        this.service.findPlaceFromQuery(request, (candidates, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK)
            return reject(
              new Error(`Error fecthin place candidates with status: ${status}`)
            );
          if (!candidates || candidates.length === 0)
            return reject(new Error("No place found"));

          this.getPlaceDetailsById(candidates[0].place_id)
            .then((place) => {
              if (!place) return resolve(null);

              if (
                place.types?.some((type) =>
                  ExcludedGooglePlacesTypes.includes(type)
                )
              )
                return resolve(null);

              const placeLatLng = {
                lat: place.geometry?.location?.lat() || Infinity,
                lng: place.geometry?.location?.lng() || Infinity,
              };

              if (calculateDistanceBetween(placeLatLng, selectedMarker) < 20)
                return resolve(place);

              resolve(null);
            })
            .catch(reject);
        });
      });
    } catch (error) {
      console.error("[POS_DETAILS_SERVICE] error: ", error);
      return null;
    }
  }
}
